import { defineStore } from "pinia";
import { ref } from "vue";

export const useCelebrationStore = defineStore("celebration", () => {
  // Refs
  const celebrationVisible = ref(false);
  const celebrationType = ref("confetti");

  // Actions
  /**
   * Show the confetti for a couple of seconds.
   * @returns {any}
   * @param type
   * @param duration in ms
   */
  const showCelebration = (type = "confetti", duration) => {
    celebrationVisible.value = true;
    celebrationType.value = type;

    // Set defaults
    let timeDuration = type === "confetti" ? 10000 : 3500;

    // Overwrite defaults if duration is set
    if (duration) {
      timeDuration = duration;
    }

    setTimeout(() => {
      celebrationVisible.value = false;
    }, timeDuration);
  };

  /**
   * Hide the confetti immediately.
   * @returns {any}
   */
  const hideCelebration = () => {
    celebrationVisible.value = false;
  };

  return {
    showCelebration,
    hideCelebration,
    celebrationVisible,
    celebrationType,
  };
});

import * as Sentry from "@sentry/vue";

export default {
  install: (app) => {
    if (import.meta.env.VITE_APP_ENV !== "development") {
      Sentry.init({
        app,
        dsn: "https://248bce06f2d99e24acf38a2ce1125cc9@o4506592533610496.ingest.sentry.io/4506672063905792",
        environment: import.meta.env.VITE_APP_ENV,
        integrations: [
          Sentry.browserTracingIntegration({
            instrumentNavigation: false,
            instrumentPageLoad: false,
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  },
};

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import paginationModel from "models/pagination.js";
import axios from "axios";

const DEFAULTNOTIFICATIONSPERPAGE = 10;

export const useNotificationStore = defineStore("notifications", () => {
  // Refs
  const notifications = ref([]);
  const unreadNotificationsCount = ref(0);

  // Fetching states
  const isFetching = ref({
    notifications: false,
  });

  // Pagination
  const pagination = ref({
    ...paginationModel,
    ...{ per_page: DEFAULTNOTIFICATIONSPERPAGE },
  });

  // Helpers
  const getNotificationTitle = (message) => {
    const condition = (searchTerm) => {
      return message?.toLowerCase().includes(searchTerm) ?? false;
    };

    if (condition("actieplan")) return "Actieplan";
    if (condition("portfolio")) return "Portfolio";
    if (condition("feedback")) return "Feedback";
    if (condition("taak")) return "Taak";
    if (condition("leerling-stoplicht")) return "Leerling stoplicht";
    if (condition("activiteit")) return "Portfolio";
  };

  const userId = computed(() => {
    let id = JSON.parse(localStorage.getItem("user")).id;

    if (localStorage.getItem("employee_id")) {
      id = JSON.parse(localStorage.getItem("employee_id"));
    } else if (localStorage.getItem("student_user_id")) {
      id = JSON.parse(localStorage.getItem("student_user_id"));
    }

    return id;
  });

  const setPaginationMeta = (meta) => {
    // Create a map to change the keys from the backend
    // We recieve current_page but we have to send it as page
    const keyMap = {
      current_page: "page",
    };

    const updatedMeta = Object.fromEntries(
      Object.entries(meta)
        .map(([key, value]) => {
          // Map the keys to our set keys
          const newKey = keyMap[key] || key;

          return [newKey, value];
        })
        // Add only keys that are part of the default model
        .filter(([key]) => Object.keys(paginationModel).includes(key)),
    );

    Object.assign(pagination.value, updatedMeta);
  };

  // Methods
  const fetchNotifications = async ({
    id = null,
    specificUser = false,
  } = {}) => {
    const { page, per_page } = pagination.value;

    isFetching.value["notifications"] = true;

    try {
      const {
        data: { data: fetchedData, meta },
      } = await axios.get(
        `/api/${specificUser ? "student" : "user"}/${
          id || userId.value
        }/notifications`,
        {
          params: {
            ...{ page, per_page },
          },
        },
      );

      // Set the current fetched pagination information
      setPaginationMeta(meta);

      notifications.value = fetchedData;

      return notifications.value;
    } catch (error) {
      console.log("🚀 ~ fetchNotifications ~ error", error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["notifications"] = false;
    }
  };

  const fetchUnreadNotificationsCount = async () => {
    try {
      const { data } = await axios.get(
        `/api/user/${userId.value}/unseen-notification-count`,
      );

      unreadNotificationsCount.value = data.count;

      return unreadNotificationsCount.value;
    } catch (error) {
      console.log("🚀 ~ notificationStore.js ~ error:", error);
    }
  };

  const setNotificationAsRead = async ({ notificationId } = {}) => {
    try {
      const { data } = await axios.post(
        `/api/user/${userId.value}/set-notification-read/${notificationId}`,
      );

      return data;
    } catch (error) {
      console.log("🚀 ~ notificationStore.js ~ error:", error);
    }
  };

  const setAllNotificationsAsRead = async () => {
    try {
      const { data } = await axios.post(
        `/api/user/${userId.value}/set-all-notifications-read`,
      );

      return data;
    } catch (error) {
      console.log("🚀 ~ notificationStore.js ~ error:", error);
    }
  };

  return {
    notifications,
    isFetching,
    fetchNotifications,
    fetchUnreadNotificationsCount,
    getNotificationTitle,
    setNotificationAsRead,
    setAllNotificationsAsRead,
    pagination,
    unreadNotificationsCount,
  };
});

import axios from "axios";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";
import useHelpers from "composables/useHelpers";
import { useRouter } from "vue-router";

export const useMeetingStore = defineStore("meeting", () => {
  // Router
  const router = useRouter();

  // Helpers
  const { schoolId } = useHelpers();

  // Refs
  const user = ref(JSON.parse(localStorage.getItem("user")));
  const meeting = ref({});
  const initialStudents = ref([]);
  const initialSchedules = ref([]);

  // Computed
  const isAllowed = computed(() => {
    const allowedRoles = ["manager", "webmaster", "organizer"];

    return user.value.role.some((role) => allowedRoles.includes(role));
  });

  const isOverview = computed(() => {
    return !!router.currentRoute.value.query.overview;
  });

  const isEditable = computed(() => {
    if (!meeting.value?.data) {
      // Return true because a new meeting is created
      return true;
    }

    const statusCanEdit = ["open", "closed_modify", "concept"];

    return statusCanEdit.includes(meeting.value.data.status);
  });

  const isExistingMeeting = computed(() => {
    return !!meeting.value?.data?.id;
  });

  //   Actions
  const storeStudentMeeting = async () => {
    try {
      let formData = new FormData();
      formData.set("meeting", JSON.stringify(meeting.value));

      await axios.post(`/api/school/${schoolId}/meetings`, formData);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  const storeTransitionMeeting = async () => {
    try {
      let formData = new FormData();
      formData.set("meeting", JSON.stringify(meeting.value));

      await axios.post(`/api/school/${schoolId}/transition-meetings`, formData);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  const fetchTransitionMeeting = async (meetingId) => {
    try {
      const { data } = await axios.get(`/api/transition-meetings/${meetingId}`);

      meeting.value = data;

      return data;
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  const removeMeeting = async (meeting) => {
    try {
      const { meeting_type, id } = meeting.value.data;
      let url;

      switch (meeting_type) {
        case "transition_meeting":
          url = `/api/transition-meetings/${id}`;
          break;
        case "meeting":
          url = `/api/meetings/${id}`;
          break;
      }

      return await axios.delete(url);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  const updateMeeting = async (meeting) => {
    try {
      const { meeting_type, id } = meeting.value.data;
      let url;

      switch (meeting_type) {
        case "transition_meeting":
          url = `/api/transition-meetings/${id}/update-global-info`;
          break;
        case "meeting":
          url = `/api/meetings/${id}/update-global-info`;
          break;
      }

      return await axios.post(url, meeting.value.data);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  const clearShareFeedbackAt = () => {
    if (meeting?.value?.data?.timeframes) {
      meeting.value.data.timeframes.share_feedback_at_date = null;
      meeting.value.data.timeframes.share_feedback_at_time = null;
    }

    if (meeting?.value?.timeframe_settings) {
      meeting.value.timeframe_settings.share_feedback_at_date = null;
      meeting.value.timeframe_settings.share_feedback_at_time = null;
    }
  };

  /**
   * Update students and subjects
   * @param meeting
   * @param type - 'students' or 'schedules'
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  const updateStudentsAndSubjects = async (meeting) => {
    try {
      const { id } = meeting.value.data;

      let formData = new FormData();
      formData.set("meeting", JSON.stringify(meeting.value.data));

      return await axios.post(
        `/api/transition-meetings/${id}/update-students-and-subjects`,
        formData,
      );
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  const updatePlanning = async (meeting) => {
    try {
      const { meeting_type, id, timeframe_settings } = meeting.value.data;
      let url;

      switch (meeting_type) {
        case "transition_meeting":
          url = `/api/transition-meetings/${id}/update-planning`;
          break;
        case "meeting":
          url = `/api/meetings/${id}/update-planning`;
          break;
      }

      const formData = new FormData();
      formData.set("timeframe_settings", JSON.stringify(timeframe_settings));

      return await axios.post(url, formData);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  return {
    meeting,
    initialStudents,
    initialSchedules,
    isAllowed,
    isOverview,
    isEditable,
    isExistingMeeting,
    storeStudentMeeting,
    storeTransitionMeeting,
    fetchTransitionMeeting,
    removeMeeting,
    updatePlanning,
    updateMeeting,
    updateStudentsAndSubjects,
    clearShareFeedbackAt,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMeetingStore, import.meta.hot));
}

import axios from "axios";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore("user", () => {
  // Refs
  const user = ref({});

  //   Actions
  const fetchCurrentUser = async ({ forceFetch = false } = {}) => {
    if (Object.keys(user.value).length !== 0 && !forceFetch) {
      return;
    }

    try {
      const { data: fetchedUser } = await axios.get(`/api/user/current`);

      user.value = fetchedUser.data;

      return user.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  return {
    user,
    fetchCurrentUser,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

<template>
  <MeetingModule>
    <c-input
      label="Naam"
      placeholder="Bijv: 23/24 Bespreking oktober onderbouw"
      v-model="model.name"
      lazy-rules
      required
      :rules="[
        (val) => !!val || 'Naam is verplicht',
        (val) => val.length > 3 || 'De naam moet minimaal 4 karakters bevatten',
      ]"
      @update:model-value="modelUpdate"
    />
  </MeetingModule>
</template>

<script setup>
import { onMounted } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});

const isStepCompleted = defineModel("isStepCompleted");

// Update model value
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length > 3;
};

onMounted(() => {
  // Set a default value for the model when it's empty
  if (model.value?.name == null) {
    model.value.name = "";
  }

  // Check the model onMounted
  modelUpdate(model.value.name);
});
</script>

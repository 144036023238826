import useHelpers from "composables/useHelpers.js";

const { mappedTerms } = useHelpers();

const routes = [
  {
    name: "myLessongroups",
    path: "/my-lessongroups",
    redirect: {
      name: "myLessongroupsStudentMeetings",
    },
    meta: {
      title: `Mijn lesgroepen`,
      role: "teacher",
      isMenuItem: true,
      settingRequired: ["studentMeeting"],
      menuOrder: 5,
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
        "teacher",
      ],
      icon: "fa-users",
    },
    children: [
      {
        name: "myLessongroupsStudentMeetings",
        path: "meetings/:mentor?/:id?",
        component: () => import("pages/my-lessongroups/StudentMeetings.vue"),
        meta: {
          title: `Leerlingbespreking`,
          isSubmenuItem: true,
          settingRequired: ["studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
        children: [
          {
            path: "student/:studentId",
            name: "myLessongroupsStudentMeetingsStudent",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
      {
        name: "myLessongroupsTransitionMeetings",
        path: "transition-meetings",
        component: () => import("pages/my-lessongroups/TransitionMeetings.vue"),
        meta: {
          title: "Bevorderingsbespreking",
          isSubmenuItem: true,
          settingRequired: ["transitionMeeting", "studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
        children: [
          {
            path: "student/:studentId",
            name: "myLessongroupsTransitionMeetingsStudent",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
      {
        path: "portfolio",
        name: "myLessongroupsPortfolio",
        component: () =>
          import("pages/my-lessongroups/MyLessongroupsPortfolio.vue"),
        meta: {
          title: "Portfolio",
          isSubmenuItem: true,
          settingRequired: ["portfolio"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
        children: [
          {
            path: "student/:studentId",
            name: "myLessongroupsPortfolioDetail",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
    ],
  },
];

export default routes;

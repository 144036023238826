<template>
  <q-editor
    v-bind="$attrs"
    min-height="5rem"
    paragraph-tag="p"
    content-class="tw-prose"
    :toolbar
  />
</template>

<script setup>
import { ref, computed } from "vue";
import { useQuasar } from "quasar";

// Quasar
const $q = useQuasar();

const props = defineProps({
  simple: {
    type: Boolean,
    default: false,
  },
});

const fullToolbar = [
  [
    {
      label: $q.lang.editor.align,
      icon: $q.iconSet.editor.align,
      fixedLabel: true,
      list: "only-icons",
      options: ["left", "center", "right", "justify"],
    },
  ],
  ["bold", "italic", "underline", "subscript", "superscript"],
  ["token", "hr", "link", "custom_btn"],
  [
    {
      label: $q.lang.editor.formatting,
      icon: $q.iconSet.editor.formatting,
      list: "no-icons",
      options: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "code"],
    },
    {
      label: $q.lang.editor.fontSize,
      icon: $q.iconSet.editor.fontSize,
      fixedLabel: true,
      fixedIcon: true,
      list: "no-icons",
      options: [
        "size-1",
        "size-2",
        "size-3",
        "size-4",
        "size-5",
        "size-6",
        "size-7",
      ],
    },
    "removeFormat",
  ],
  ["quote", "unordered", "ordered", "outdent", "indent"],
  ["undo", "redo"],
];

const simpleToolbar = [
  [
    {
      label: $q.lang.editor.align,
      icon: $q.iconSet.editor.align,
      fixedLabel: true,
      list: "only-icons",
      options: ["left", "center", "right", "justify"],
    },
  ],
  ["bold", "italic", "underline"],
];

const toolbar = computed(() => (props.simple ? simpleToolbar : fullToolbar));
</script>

<template>
  <q-checkbox v-bind="$attrs" :size class="tw-flex" :class="classes">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>

    <div v-if="tooltip || required" class="tw-flex tw-items-center tw-gap-x-2">
      <div v-if="required" class="tw-text-[7px]">verplicht</div>

      <q-icon
        class="tooltip"
        v-if="tooltip"
        :size
        name="fa-solid fa-info-circle"
      >
        <q-tooltip anchor="top middle" self="bottom middle"
          >{{ tooltip }}
        </q-tooltip>
      </q-icon>
    </div>
  </q-checkbox>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  borderless: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "xs",
  },
  tooltip: {
    type: String,
    required: false,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  return [
    ...(!props.borderless ? ["border"] : []),
    ...(props.required ? ["required"] : []),
  ];
});
</script>

<style lang="scss">
div.q-checkbox {
  @apply tw-flex tw-select-none;

  border-radius: $generic-border-radius;

  &.border {
    border: 1px solid $grey;
    transition: border $generic-hover-transition;

    &:hover {
      border: 1px solid $primary;
    }
  }

  &.disabled {
    pointer-events: auto !important;
    opacity: 1 !important;

    .q-checkbox__label {
      @apply tw-opacity-75;
    }

    .q-checkbox__bg.absolute {
      opacity: 0.5;
    }

    &.border:hover {
      border-color: inherit !important;
    }
  }

  &:has(.q-checkbox__label:not(:empty)) {
    display: flex;
    padding-right: 8px;
  }

  .q-checkbox__label {
    @apply tw-flex tw-flex-1 tw-gap-x-2 tw-text-pretty;
  }

  .q-checkbox__label:has(.q-icon),
  &.required .q-checkbox__label {
    @apply tw-items-center tw-justify-between;
  }
}
</style>

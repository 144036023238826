<template>
  <q-badge color="primary" v-bind="$attrs">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-badge>
</template>

<style lang="scss">
div.q-badge {
  @apply tw-select-none ;
}
</style>

<template>
  <q-field borderless v-model="model" bg-color="transparent" v-bind="$attrs">
    <div class="tw-block tw-w-full">
      <slot />
    </div>
  </q-field>
</template>

<script setup>
const model = defineModel();
</script>

<style lang="scss">
.q-field {
  pointer-events: none;

  &--inner,
  &__control,
  &__control-container {
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  &.q-field--error {
    .q-editor__content,
    .q-checkbox:not(.disabled),
    .q-input {
      @apply tw-border-red-700 tw-bg-red-50/50;
    }

    .q-checkbox__inner {
      //@apply tw-text-gray-500;
    }

    .q-field__control.text-negative .q-field__control-container {
      @apply tw-text-primary;
    }

    // Editor icons normal color (B, I, U etc)
    .q-editor {
      @apply tw-text-primary;
    }

    // Hide the icon that shows up when there is an error
    .q-field__append {
      @apply tw-hidden;
    }
  }
}
</style>

import useHelpers from "composables/useHelpers.js";

const { mappedTerms } = useHelpers();

const routes = () => [
  {
    name: "myStudents",
    path: "/my-students",
    redirect: {
      name: "myStudentsStudentMeetings",
    },
    meta: {
      title: `Mijn ${mappedTerms().students}`,
      role: "teacher",
      isMenuItem: true,
      settingRequired: ["studentMeeting"],
      menuOrder: 4,
      icon: "fa-graduation-cap",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
        "teacher",
      ],
    },
    children: [
      {
        name: "myStudentsStudentMeetings",
        path: "meetings",
        component: () => import("pages/my-students/StudentMeetings.vue"),
        meta: {
          title: `Leerlingbespreking`,
          isSubmenuItem: true,
          settingRequired: ["studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
        children: [
          {
            path: "student/:studentId",
            name: "myStudentsStudentMeetingsStudent",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
      {
        name: "myStudentsTransitionMeetings",
        path: "transition-meetings",
        component: () => import("pages/my-students/TransitionMeetings.vue"),
        meta: {
          title: "Bevorderingsbespreking",
          isSubmenuItem: true,
          settingRequired: ["transitionMeeting", "studentMeeting"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
        children: [
          {
            path: "student/:studentId",
            name: "myStudentsTransitionMeetingsStudent",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
      {
        path: "portfolio",
        name: "myStudentsPortfolio",
        component: () => import("pages/my-students/MyStudentsPortfolio.vue"),
        meta: {
          title: "Portfolio",
          isSubmenuItem: true,
          settingRequired: ["portfolio"],
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
        children: [
          {
            path: "student/:studentId",
            name: "myStudentsPortfolioDetail",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
    ],
  },
];

export default routes;

<template>
  <ol>
    <li>
      <!-- Make a component from these list items they are all the same -->
      <span class="tw-font-bold">Datum A1 (verplicht)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer wordt de bespreking open gezet?
      </label>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            v-model="timeframeSettings.opens_at_date"
            :max-date="dateRangeOptions.a1.date.max"
            @update:model-value="checkSyncSchedules"
          />
        </div>

        <div class="tw-col-span-1 tw-flex">
          <c-input-time-select
            v-model="timeframeSettings.opens_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              timeframeSettings.opens_at_date === '' ||
              timeframeSettings.opens_at_date == null
            "
            @update:model-value="checkSyncSchedules"
            required
          />
        </div>
      </div>

      <div>
        <label
          class="tw-mb-2 tw-mt-2 tw-block tw-tracking-wide tw-text-gray-700"
        >
          {{ sendEmailAutomaticallyLabel }}
        </label>

        <div class="tw-grid tw-gap-4 lg:tw-w-1/2">
          <div>
            <c-radio-group
              :options="[
                { value: 1, label: 'Ja', id: 'send_invite_yes' },
                { value: 0, label: 'Nee', id: 'send_invite_no' },
              ]"
              v-model="timeframeSettings.invite_coworkers"
              inline
            />
          </div>
        </div>
      </div>

      <div>
        <label
          class="tw-mb-2 tw-mt-2 tw-block tw-tracking-wide tw-text-gray-700"
        >
          Actualiseer het vakkenpakket bij start bespreking (aanbevolen)
        </label>

        <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
          <div class="tw-col-span-1">
            <c-radio
              label="Ja"
              v-model="timeframeSettings.sync_schedules"
              :val="1"
              :disable="timeIsSmallerThanNow['opensAtDate']"
            />
          </div>
          <div class="tw-col-span-1">
            <c-radio
              label="Nee"
              v-model="timeframeSettings.sync_schedules"
              :val="0"
              :disable="timeIsSmallerThanNow['opensAtDate']"
            />
          </div>
        </div>
      </div>

      <div class="tw-grid tw-gap-4 lg:tw-w-1/2">
        <Alert v-if="timeIsSmallerThanNow['opensAtDate']" status="warning"
          >De optie "vakkenpakket actualiseren" kan niet gewijzigd worden omdat
          de start van de bespreking in het verleden ligt. Het huidige
          vakkenpakket wordt toegepast.</Alert
        >
      </div>
    </li>

    <li>
      <span class="tw-font-bold">Datum A2 (optioneel)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer staat er tijd gereserveerd voor het invullen? (optioneel)
      </label>

      <DateFromTo
        :change-to-min-date="false"
        :min-date="dateRangeOptions.a2.date.min"
        :min-time="dateRangeOptions.a2.time.min"
        v-model="timeframeSettings.feedback_reservations"
        :multiple="true"
      />
    </li>

    <li>
      <span class="tw-font-bold">Datum B1 (optioneel)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer wordt de bespreking gesloten, maar zijn aanpassingen nog wel
        mogelijk?
      </label>

      <div v-if="showSteps.b1">
        <div class="tw-mb-2 tw-grid tw-grid-cols-7 tw-gap-4 lg:tw-w-1/2">
          <div class="tw-col-span-3">
            <c-input-date-select
              class="tw-flex-1"
              label="Datum"
              placeholder="dd-mm-jjjj"
              v-model="timeframeSettings.closed_modify_at_date"
              :min-date="dateRangeOptions.b1.date.min"
              :change-to-min-date="false"
            />
          </div>

          <div class="tw-col-span-3">
            <c-input-time-select
              v-model="timeframeSettings.closed_modify_at_time"
              class="tw-flex-1"
              label="Tijdstip"
              :disable="
                timeframeSettings.closed_modify_at_date === '' ||
                timeframeSettings.closed_modify_at_date == null
              "
              :compare-time="dateRangeOptions.b1.time.compare"
              :min-time="dateRangeOptions.b1.time.min"
              required
            />
          </div>

          <div class="tw-col-span-1 tw-flex tw-items-center">
            <c-button-icon
              icon="close"
              color="negative"
              @click="showSteps.b1 = !showSteps.b1"
            />
          </div>
        </div>

        <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
          Automatisch een reminder sturen naar docenten die nog niet alles
          hebben ingevuld, 48 uur vóór deze sluiting?
        </label>

        <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
          <div class="tw-col-span-1">
            <c-radio
              :val="1"
              label="Ja"
              v-model="timeframeSettings.send_reminder_before_closing_modify"
            />
          </div>

          <div class="tw-col-span-1">
            <c-radio
              :val="0"
              label="Nee"
              v-model="timeframeSettings.send_reminder_before_closing_modify"
            />
          </div>
        </div>
      </div>

      <c-button-icon
        v-if="!showSteps.b1"
        icon="add"
        @click="showSteps.b1 = !showSteps.b1"
      />
    </li>

    <li>
      <span class="tw-font-bold">Datum B2 (optioneel)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer staat er tijd gereserveerd om de feedback te inventariseren en
        speeddates voor te bereiden?
      </label>

      <DateFromTo
        :min-date="dateRangeOptions.b2.date.min"
        :min-time="dateRangeOptions.b2.time.min"
        v-model="timeframeSettings.speed_date_preparations"
        :multiple="true"
        :change-to-min-date="false"
      />
    </li>

    <li>
      <span class="tw-font-bold">Datum C1 (optioneel)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer zijn de speedddates?
      </label>

      <DateFromTo
        :min-date="dateRangeOptions.c1.date.min"
        :min-time="dateRangeOptions.c1.time.min"
        v-model="timeframeSettings.speed_dates"
        :change-to-min-date="false"
      />
    </li>

    <li>
      <span class="tw-font-bold">Datum C2 (verplicht)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer wordt de bespreking definitief gesloten, en zijn aanpassingen
        niet meer mogelijk?<br />
        <span class="text-grey-700 tw-italic">
          Vanaf dit moment is de ingevulde feedback niet meer zichtbaar onder
          Mijn Besprekingen, maar ziet de vakdocent deze onder Mijn
          Lesgroepen.</span
        >
      </label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            placeholder="dd-mm-jjjj"
            v-model="timeframeSettings.closed_no_modify_at_date"
            :min-date="dateRangeOptions.c2.date.min"
            :change-to-min-date="false"
          />
        </div>

        <div class="tw-col-span-1">
          <c-input-time-select
            v-model="timeframeSettings.closed_no_modify_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              timeframeSettings.closed_no_modify_at_date === '' ||
              timeframeSettings.closed_no_modify_at_date == null
            "
            :compare-time="dateRangeOptions.c2.time.compare"
            :min-time="dateRangeOptions.c2.time.min"
            required
          />
        </div>
      </div>

      <label class="tw-mb-2 tw-mt-2 tw-block tw-tracking-wide tw-text-gray-700">
        Automatisch een reminder sturen naar docenten die nog niet alles hebben
        ingevuld, 48 uur vóór deze sluiting?
      </label>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-radio
            :val="1"
            label="Ja"
            v-model="timeframeSettings.send_reminder_before_closing_no_modify"
          />
        </div>
        <div class="tw-col-span-1">
          <c-radio
            :val="0"
            label="Nee"
            v-model="timeframeSettings.send_reminder_before_closing_no_modify"
          />
        </div>
      </div>
    </li>

    <li>
      <span class="tw-font-bold">
        Datum D
        <span v-if="mailStudent"> (verplicht) </span>
      </span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer wordt de feedback gedeeld met de leerling?
      </label>

      <div
        class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2"
        v-if="mailStudent"
      >
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            placeholder="dd-mm-jjjj"
            v-model="timeframeSettings.share_feedback_at_date"
            :min-date="dateRangeOptions.d.date.min"
            :change-to-min-date="false"
          />
        </div>
        <div class="tw-col-span-1">
          <c-input-time-select
            v-model="timeframeSettings.share_feedback_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              timeframeSettings.share_feedback_at_date === '' ||
              timeframeSettings.share_feedback_at_date == null
            "
            :compare-time="dateRangeOptions.d.time.compare"
            :min-time="dateRangeOptions.d.time.min"
            required
          />
        </div>

        <label
          class="tw-col-span-full tw--mb-2 tw-block tw-tracking-wide tw-text-gray-700"
        >
          Hoe moet de feedback gedeeld worden met de leerling?
        </label>

        <div class="tw-col-span-full tw-flex tw-flex-col tw-gap-2">
          <c-radio
            v-model="timeframeSettings.auto_share"
            :val="1"
            label="Automatisch op bovenstaand tijdstip"
          />

          <c-radio
            v-model="timeframeSettings.auto_share"
            :val="0"
            label="Handmatig door mentor"
          />
        </div>
      </div>

      <div class="text-grey-700" v-else>
        De feedback wordt niet gedeeld met de leerling
      </div>
    </li>

    <li>
      <span class="tw-font-bold">Datum E1 (optioneel)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer zijn de driehoeksgesprekken?
      </label>

      <div
        class="tw-mb-5 tw-grid tw-grid-cols-7 tw-gap-4 lg:tw-mb-2 lg:tw-w-1/2"
        v-if="showSteps.e1"
      >
        <div class="tw-col-span-3">
          <c-input-date-select
            class="tw-flex-1"
            label="Startdatum"
            placeholder="dd-mm-jjjj"
            v-model="timeframeSettings.triangle_conversation_from"
            :min-date="dateRangeOptions.e1.date.min"
            :change-to-min-date="false"
          />
        </div>
        <div class="tw-col-span-3 tw-flex tw-items-center">
          <div class="tw--ml-1 tw-mr-3">tot</div>
          <div class="tw-h-full tw-w-full">
            <c-input-date-select
              class="tw-flex-1"
              label="Einddatum"
              placeholder="dd-mm-jjjj"
              v-model="timeframeSettings.triangle_conversation_till"
              :min-date="dateRangeOptions.e1.date.max"
              :change-to-min-date="false"
            />
          </div>
        </div>
        <div class="tw-col-span-1 tw-flex tw-items-center">
          <c-button-icon
            icon="close"
            color="negative"
            @click="showSteps.e1 = !showSteps.e1"
          />
        </div>
      </div>

      <c-button-icon
        v-if="!showSteps.e1"
        icon="add"
        @click="showSteps.e1 = !showSteps.e1"
      />
    </li>

    <li>
      <span class="tw-font-bold">Datum E2 (verplicht)</span>
      <label class="tw-mb-2 tw-block tw-tracking-wide tw-text-gray-700">
        Wanneer is de bespreking afgerond?<br />
        <span class="tw-text-grey-700 tw-italic">
          Vanaf dit moment verdwijnt de tijdlijn uit Mijn Besprekingen en kunnen
          leerlingen geen actieplan meer toevoegen.</span
        >
      </label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-w-1/2">
        <div class="tw-col-span-1">
          <c-input-date-select
            class="tw-flex-1"
            label="Datum"
            placeholder="dd-mm-jjjj"
            v-model="timeframeSettings.archived_at_date"
            :min-date="dateRangeOptions.e2.date.min"
            :change-to-min-date="false"
          />
        </div>
        <div class="tw-col-span-1">
          <c-input-time-select
            v-model="timeframeSettings.archived_at_time"
            class="tw-flex-1"
            label="Tijdstip"
            :disable="
              timeframeSettings.archived_at_date === '' ||
              timeframeSettings.archived_at_date == null
            "
            :compare-time="dateRangeOptions.e2.time.compare"
            :min-time="dateRangeOptions.e2.time.min"
            required
          />
        </div>
      </div>
    </li>
  </ol>

  <Alert v-if="showStatusUpdate" status="warning">
    <span class="tw-font-bold">Let op:</span>&nbsp;bij het opslaan van deze
    bespreking wordt de status op
    <span class="tw-font-bold tw-lowercase">&nbsp;{{ dateStatus }}&nbsp;</span>
    gezet.
  </Alert>

  <Alert
    status="warning"
    v-if="
      timeIsSmallerThanNow['opensAtDate'] &&
      edit &&
      meeting.data.status === 'concept'
    "
  >
    Wanneer je de bespreking bijwerkt wordt ook het vakkenpakket
    gesynchroniseerd
  </Alert>
</template>

<script setup>
import { computed, onMounted, ref, watch, onBeforeMount, reactive } from "vue";
import Alert from "@/components/global/Alert.vue";
import moment from "moment";
import { isArray } from "chart.js/helpers";
import { useMeetingStore } from "store/modules/meetingStore";
import useHelpers from "@/composables/useHelpers";
import { DEFAULTSTARTTIME } from "common/timeOptions";
import DateFromTo from "components/date/DateFromTo.vue";
import { getDateString, fromDutchToIntlDate } from "common/dateHelpers";
import { storeToRefs } from "pinia";

const emit = defineEmits(["updateVal"]);

// Store
const meetingStore = useMeetingStore();
const { meeting } = storeToRefs(meetingStore);

const { mapStatus } = useHelpers();
const dateStatus = ref(null);

const props = defineProps({
  timeframes: {
    type: Object,
    default: {},
  },
  edit: {
    type: Boolean,
    default: false,
  },
  mailStudent: {
    type: [Boolean, Number],
    required: false,
  },
  status: {
    type: String,
    default: "",
  },
});

// Refs
const timeIsSmallerThanNow = ref({});
const timeframeSettings = ref({
  sync_schedules: 1,
  auto_share: 1,
  feedback_reservations: [],
  speed_date_preparations: [],
  closed_modify_at_date: null,
  closed_modify_at_time: null,
  speed_dates: [],
  opens_at_date: null,
  opens_at_time: null,
  closed_no_modify_at_date: null,
  closed_no_modify_at_time: null,
  share_feedback_at_date: null,
  share_feedback_at_time: null,
  archived_at_time: null,
  archived_at_date: null,
  send_reminder_before_closing_no_modify: 0,
  invite_coworkers: 1,
  send_reminder_before_closing_modify: 0,
});

const showSteps = ref({
  b1: false,
  e1: false,
});

// Timeframs is not reactive so we need to assign it to a reactive object
watch(props.timeframes, () => {
  if (props.timeframes) {
    Object.assign(timeframeSettings.value, props.timeframes);
  }
});

onMounted(() => {
  if (props.timeframes) {
    Object.assign(timeframeSettings.value, props.timeframes);
  }

  showSteps.value.b1 = !(
    !timeframeSettings.value.closed_modify_at_date &&
    !timeframeSettings.value.closed_modify_at_time
  );

  showSteps.value.e1 = !(
    !timeframeSettings.value.triangle_conversation_from &&
    !timeframeSettings.value.triangle_conversation_till
  );

  checkDates();
});

// Date refs
const dateTypes = ref(["a1", "a2", "b1", "b2", "c1", "c2", "d", "e1", "e2"]);
const availableDateTimeOptions = reactive({});

onBeforeMount(() => {
  // Add the available date types to the object
  dateTypes.value.map((key) => {
    availableDateTimeOptions[key] = { date: {}, time: {} };
  });
});

// Hide or show the status update Alert component.
const showStatusUpdate = computed(() => {
  return (
    dateStatus.value !== null && mapStatus(props.status) !== dateStatus.value
  );
});

// Return an object with the min and max date per date type
const dateRangeOptions = computed(() => {
  const {
    closed_modify_at_date,
    closed_modify_at_time,
    speed_date_preparations,
    feedback_reservations,
    speed_dates,
    closed_no_modify_at_date,
    closed_no_modify_at_time,
    share_feedback_at_date,
    share_feedback_at_time,
    triangle_conversation_from,
    triangle_conversation_till,
    archived_at_date,
  } = timeframeSettings.value;
  let { opens_at_date, opens_at_time } = timeframeSettings.value;

  opens_at_date = opens_at_date || getDateString();
  opens_at_time = opens_at_time || DEFAULTSTARTTIME;

  // This can be optimized as we reuse a lot of logic
  Object.keys(availableDateTimeOptions).forEach((key) => {
    switch (key) {
      case "a1":
        availableDateTimeOptions[key]["date"]["max"] = getDateString(
          Date.now() + 48384e5,
        );
        break;
      case "a2":
        availableDateTimeOptions[key]["date"]["min"] = opens_at_date;
        availableDateTimeOptions[key]["time"]["min"] = opens_at_time;
        break;
      case "b1":
        // Set min date and time when feedback_reservation is filled
        if (isArrayAndHasValue(feedback_reservations, "end_date")) {
          setMinDateTime(
            feedback_reservations,
            availableDateTimeOptions[key],
            closed_modify_at_date,
          );
        } else {
          // Feedback reservations is not filled, check against the opens at date and time
          availableDateTimeOptions[key]["date"]["min"] = opens_at_date;
          availableDateTimeOptions[key]["time"]["min"] = opens_at_time;
          availableDateTimeOptions[key]["time"]["compare"] =
            opens_at_date === closed_modify_at_date;
        }
        break;
      case "b2":
        // Set min date and time when feedback_reservation is filled
        // We don't have to set the time compare because the DateFromTo component does that for us
        if (closed_modify_at_date) {
          availableDateTimeOptions[key]["date"]["min"] = closed_modify_at_date;
          availableDateTimeOptions[key]["time"]["min"] = closed_modify_at_time;
        } else if (isArrayAndHasValue(feedback_reservations, "end_date")) {
          setMinDateTime(feedback_reservations, availableDateTimeOptions[key]);
        } else {
          // Feedback reservations is not filled, check against the opens at date and time
          availableDateTimeOptions[key]["date"]["min"] = opens_at_date;
          availableDateTimeOptions[key]["time"]["min"] = opens_at_time;
        }
        break;
      case "c1":
        // Set min date and time when speed_date_preparations are filled
        // We don't have to set the time compare because the DateFromTo component does that for us
        if (isArrayAndHasValue(speed_date_preparations, "end_date")) {
          setMinDateTime(
            speed_date_preparations,
            availableDateTimeOptions[key],
          );
        } else if (isArrayAndHasValue(feedback_reservations, "end_date")) {
          setMinDateTime(feedback_reservations, availableDateTimeOptions[key]);
        } else {
          availableDateTimeOptions[key]["date"]["min"] = opens_at_date;
          availableDateTimeOptions[key]["time"]["min"] = opens_at_time;
        }
        break;
      case "c2":
        if (isArrayAndHasValue(speed_dates, "end_date")) {
          setMinDateTime(
            speed_dates,
            availableDateTimeOptions[key],
            closed_no_modify_at_date,
          );
        } else if (isArrayAndHasValue(speed_date_preparations, "end_date")) {
          setMinDateTime(
            speed_date_preparations,
            availableDateTimeOptions[key],
            closed_no_modify_at_date,
          );
        } else if (isArrayAndHasValue(feedback_reservations, "end_date")) {
          setMinDateTime(
            feedback_reservations,
            availableDateTimeOptions[key],
            closed_no_modify_at_date,
          );
        } else {
          // Feedback reservations is not filled, check against the opens at date and time
          availableDateTimeOptions[key]["date"]["min"] = opens_at_date;
          availableDateTimeOptions[key]["time"]["min"] = opens_at_time;
          availableDateTimeOptions[key]["time"]["compare"] =
            closed_no_modify_at_date === opens_at_date;
        }
        break;
      case "d":
        availableDateTimeOptions[key]["date"]["min"] = closed_no_modify_at_date;
        availableDateTimeOptions[key]["time"]["min"] = closed_no_modify_at_time;
        availableDateTimeOptions[key]["time"]["compare"] =
          availableDateTimeOptions[key]["date"]["min"] ===
          share_feedback_at_date;
        break;
      case "e1":
        availableDateTimeOptions[key]["date"]["min"] = props.mailStudent
          ? share_feedback_at_date
          : closed_no_modify_at_date;
        availableDateTimeOptions[key]["date"]["max"] =
          triangle_conversation_from;
        break;
      case "e2":
        if (triangle_conversation_till) {
          // Set correct e2 min date by checking if triangle_conversation_till is used
          availableDateTimeOptions[key]["date"]["min"] =
            triangle_conversation_till;

          // e2 does not have to compare as there is no time in triangle conversation
          availableDateTimeOptions[key]["time"]["compare"] = false;
        } else if (share_feedback_at_date) {
          // Set correct e2 min date when share_feedback_at_date is used
          availableDateTimeOptions[key]["date"]["min"] = share_feedback_at_date;

          // Set the correct e2 min time when share_feedback_at_date is used
          availableDateTimeOptions[key]["time"]["min"] = share_feedback_at_time;

          // Check if e2 should compare times when the dates are the same
          availableDateTimeOptions[key]["time"]["compare"] =
            availableDateTimeOptions[key]["date"]["min"] === archived_at_date;
        } else {
          availableDateTimeOptions[key]["date"]["min"] =
            closed_no_modify_at_date;

          // Set the e2 min time the same as closed_no_modify_at_time
          availableDateTimeOptions[key]["time"]["min"] =
            closed_no_modify_at_time;

          // Check if e2 should compare times when the dates are the same
          availableDateTimeOptions[key]["time"]["compare"] =
            availableDateTimeOptions[key]["date"]["min"] === archived_at_date;
        }
        break;
    }
  });

  return availableDateTimeOptions;
});

const setMinDateTime = (
  valueToCheck,
  availableDateTimeOptions,
  compareToValue = false,
) => {
  // Set min date and time
  availableDateTimeOptions["date"]["min"] = isArrayAndHasValue(
    valueToCheck,
    "end_date",
  );

  // Set correct min time
  availableDateTimeOptions["time"]["min"] = isArrayAndHasValue(
    valueToCheck,
    "end_time",
  );

  // Check if the the dates are the same so the time should compare
  // Set false so the min-time is not being applied when the current selected date is not the min date
  if (compareToValue !== false) {
    availableDateTimeOptions["time"]["compare"] =
      availableDateTimeOptions["date"]["min"] === compareToValue;
  } else {
    availableDateTimeOptions["time"]["compare"] = false;
  }
};

// Check if the value is an actual array,
// has a length of more than 0 and has an end_date
// Return the value or false
const isArrayAndHasValue = (value, key) => {
  return value &&
    isArray(value) &&
    value.length > 0 &&
    !!value[value.length - 1]?.[key]
    ? value[value.length - 1][key]
    : false;
};

watch(
  () => showSteps.value.b1,
  () => {
    if (!showSteps.value.b1) {
      timeframeSettings.value.closed_modify_at_date = undefined;
      timeframeSettings.value.closed_modify_at_time = undefined;
    }
  },
);

watch(
  () => showSteps.value.e1,
  () => {
    if (!showSteps.value.e1) {
      timeframeSettings.value.triangle_conversation_from = undefined;
      timeframeSettings.value.triangle_conversation_till = undefined;
    }
  },
);

watch(
  timeframeSettings.value,
  () => {
    emit("updateVal", { data: timeframeSettings.value, step: 7 });
  },
  { immediate: true, deep: true },
);

watch(
  timeframeSettings.value,
  () => {
    checkDates();
  },
  {
    deep: true,
  },
);

const sendEmailAutomaticallyLabel = computed(() => {
  let { opens_at_date, opens_at_time } = timeframeSettings.value;

  if (!opens_at_date) {
    // When the start date is not defined yet
    return "Wil je automatisch de uitnodigingen versturen bij de start van deze bespreking?";
  }

  const now = new Date();
  const meetingStartTime = fromDutchToIntlDate(opens_at_date, opens_at_time);

  if (now > meetingStartTime) {
    return "Wil je automatisch de uitnodigingen versturen op dit moment?";
  }

  return "Wil je automatisch de uitnodigingen versturen bij de start van deze bespreking?";
});

const createMoment = (date, time) => {
  moment(`${date} ${time}`, "DD-MM-YYYY HH:mm");
};

const checkSyncSchedules = () => {
  const opensAtDate = createMoment(
    timeframeSettings.value.opens_at_date,
    timeframeSettings.value.opens_at_time ?? "00:00",
  );

  if (opensAtDate < moment()) {
    timeframeSettings.value.sync_schedules = 0;
  }
};

const checkDates = () => {
  const opensAtDate = createMoment(
    timeframeSettings.value.opens_at_date,
    timeframeSettings.value.opens_at_time ?? "00:00",
  );
  const closedModifyDate = createMoment(
    timeframeSettings.value.closed_modify_at_date,
    timeframeSettings.value.closed_modify_at_time ?? "00:00",
  );
  const closedNoModifyDate = createMoment(
    timeframeSettings.value.closed_no_modify_at_date,
    timeframeSettings.value.closed_no_modify_at_time ?? "00:00",
  );
  const archivedDate = createMoment(
    timeframeSettings.value.archived_at_date,
    timeframeSettings.value.archived_at_time ?? "00:00",
  );

  timeIsSmallerThanNow.value = {
    opensAtDate: opensAtDate < moment(),
    closedModifyDate: closedModifyDate < moment(),
    closedNoModifyDate: closedNoModifyDate < moment(),
    archivedDate: archivedDate < moment(),
  };

  if (timeIsSmallerThanNow.value.archivedDate) {
    dateStatus.value = "Afgerond, aan de slag!";
    return;
  }

  if (timeIsSmallerThanNow.value.closedNoModifyDate) {
    dateStatus.value = "Gesloten, aanpassingen niet meer mogelijk";
    return;
  }

  if (timeIsSmallerThanNow.value.closedModifyDate) {
    dateStatus.value = "Gesloten, aanpassingen nog mogelijk";
    return;
  }

  if (timeIsSmallerThanNow.value.opensAtDate) {
    dateStatus.value = "Open";
    return;
  }

  dateStatus.value = "Concept";
};
</script>

<style lang="scss" scoped>
li {
  @apply tw-mb-4 tw-pb-4;
}
</style>

import axios from "axios";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const state = {
  user: {},
  status: {},
  errors: [],
};
const getters = {};
const actions = {
  getUser({ commit }) {
    if (!state.status.loggedIn) {
      commit("invalidUser");
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        axios
          .get("/api/user/current")
          .then(function (response) {
            commit("setUser", response.data);
            resolve();
          })
          .catch(function () {
            commit("invalidUser");
            resolve();
          });
      }, 1000);
    });
  },
  setErrorMessages({ commit }, errors) {
    commit("setErrors", errors);
  },
  loginUser({ commit, dispatch }, user) {
    var errorArray = [];
    //let self = this;

    axios
      .get("/api/user-by-email/" + user.email)
      .then((res) => {
        let cookie = actions.getCookie("TwoFactor" + res.data);

        axios
          .post(
            "/oauth/login",
            {
              email: user.email,
              password: user.password,
              remember_me: user.rememberMe,
              cookie: cookie,
            },
            { withCredentials: true },
          )
          .then(function (response) {
            let result = response.data;

            //self.processResults({commit}, result);
            dispatch("processResults", result);
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.message) {
                commit("setErrors", [error.response.data.message]);
                return;
              }

              // Request made and server responded
              if (error.response.data) {
                let err = error.response.data.errors;
                for (let key in err) errorArray.push(err[key][0]);
                commit("setErrors", errorArray);
              }
            }
          });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
            return;
          }

          // Request made and server responded
          if (error.response.data) {
            let err = error.response.data.errors;
            for (let key in err) errorArray.push(err[key][0]);
            commit("setErrors", errorArray);
          }
        }
      });
  },
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  processResults({ commit }, result) {
    //IDP LOGIN - ON HOLD
    /*if(result.brin_redirects) {
      localStorage.setItem('brin_redirects', JSON.stringify(result.brin_redirects));
      window.location.reload();
    }*/

    if (result.access_token) {
      localStorage.setItem("user_name", result.user.username);
      localStorage.setItem("first_name", result.user.first_name);
      localStorage.setItem("user", JSON.stringify(result.user));
      localStorage.setItem("school_id", result.user.school_id);
      localStorage.setItem("school_name", result.user.school_name);
      localStorage.setItem("tool", result.user.tool);
      localStorage.setItem("school_hash", result.user.school_hash);
      localStorage.setItem("show_nps", result.show_nps);

      let role_user_id = result.user.role_user_id;
      if (role_user_id) {
        let roles = result.user.role;

        if (
          roles.includes("teacher") ||
          roles.includes("decaan") ||
          roles.includes("teamleader") ||
          roles.includes("organizer") ||
          roles.includes("manager")
        ) {
          localStorage.setItem("employee_id", role_user_id);
          localStorage.removeItem("student_id");
          localStorage.removeItem("student_user_id");
        } else if (roles.includes("student")) {
          localStorage.setItem("student_id", role_user_id);
          localStorage.removeItem("employee_id");
        }
      }

      //Dev
      //localStorage.setItem('employee_id', 203);
      //localStorage.setItem('student_id', 5149);

      //save token
      localStorage.setItem("user_token", result.access_token);
      localStorage.setItem("refresh_token", result.refresh_token);

      if (result.user.validate_2fa) {
        localStorage.setItem("validate_2fa", "true");

        let d = new Date();
        d.setDate(d.getDate() + 30);
        let expires = "expires=" + d.toUTCString();

        document.cookie =
          "TwoFactor" +
          result.user.id +
          "=" +
          result.user.unique_device_code +
          ";" +
          expires +
          ";path=/";
      }

      let dt = new Date().getTime();
      dt = new Date(dt + parseInt(result.expires_in) * 1000);
      localStorage.setItem("expires_in", dt.toLocaleString());

      if (result.schools) {
        localStorage.setItem("school_choices", JSON.stringify(result.schools));
        localStorage.setItem("menu_locations", JSON.stringify(result.schools));
      }

      commit("setUser", result);
      window.location.reload();
    } else {
      //this.$router.push('meetings');
    }
  },
};
const mutations = {
  setErrors(state, data) {
    state.errors = data;
  },
  setUser(state, data) {
    state.status.loggedIn = true;
    state.user = data;
  },
  invalidUser(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <q-banner v-bind="$attrs" :class="banner.classes">
    <template v-slot:avatar>
      <q-icon :name="banner.icon" size="sm" />
    </template>
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-banner>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "info",
    validator: (value) => {
      return ["info", "warning", "error", "success"].includes(value);
    },
  },
});

const typeMappings = {
  info: { color: "info", icon: "fas fa-circle-info" },
  warning: { color: "warning", icon: "fas fa-exclamation-triangle" },
  error: {
    color: "negative",
    textColor: "white",
    icon: "fas fa-circle-exclamation",
  },
  success: {
    color: "positive",
    textColor: "white",
    icon: "fas fa-circle-check",
  },
};

const banner = computed(() => {
  const selectedBanner = typeMappings[props.type];

  selectedBanner.classes = [
    ...(selectedBanner?.color ? [`!tw-bg-${selectedBanner.color}`] : []),
    ...(selectedBanner?.textColor
      ? [`tw-text-${selectedBanner.textColor}`]
      : []),
  ];

  return selectedBanner;
});
</script>

<style lang="scss">
div.q-banner {
  min-height: unset;
  border-radius: $generic-border-radius;
}
</style>

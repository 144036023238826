import schoolApi from "../../api/schoolApi";

const state = () => ({
  school: null,
});

const actions = {
  async getSchool({ commit }, id) {
    const school = await schoolApi.getSchool(id);
    commit("setSchool", school);
  },
};

const mutations = {
  setSchool(state, school) {
    state.school = school;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

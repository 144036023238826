import useHelpers from "composables/useHelpers.js";

const { mappedTerms } = useHelpers();

const routes = [
  {
    path: "/overview",
    name: "overview",
    redirect: { name: "overviewStudents" },
    component: () => import("views/EmptyView.vue"),
    meta: {
      title: "Overzichten",
      roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
      settingRequired: ["studentMeeting"],
      icon: "fa-rectangle-history-circle-plus",
      isMenuItem: true,
      menuOrder: 2,
    },
    children: [
      {
        path: "students",
        name: "overviewStudents",
        component: () => import("pages/overview/OverviewStudents.vue"),
        meta: {
          title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
            1,
          )}overzicht`,
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
          settingRequired: ["studentMeeting"],
        },
        children: [
          {
            path: "student/:studentId",
            name: "overviewStudentsSidebar",
            component: () => import("components/students/StudentSidebar.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
            },
          },
        ],
      },
      {
        path: "meetings",
        name: "overviewMeetings",
        component: () => import("pages/overview/OverviewMeetings.vue"),
        meta: {
          title: "Besprekingenoverzicht",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
          settingRequired: ["studentMeeting"],
        },
      },
    ],
  },
];

export default routes;

<template>
  <c-form-group v-if="!loading" :title :sub-title :description :loading>
    <slot />
  </c-form-group>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  currentStep: {
    type: [Number, String],
    required: true,
  },
  totalSteps: {
    type: [Number, String],
    required: true,
  },
  title: {
    type: String,
    default: () => "",
  },
  subTitle: {
    type: String,
    default: () => "",
  },
  description: {
    type: String,
    default: () => "",
  },
  isSubStep: {
    type: Boolean,
    default: () => false,
  },
  loading: {
    type: Boolean,
    default: () => false,
  },
});

const title = computed(() => {
  if (props.title === "") {
    return;
  }

  return props.isSubStep
    ? `${props.title}:`
    : `Stap ${props.currentStep} van ${props.totalSteps}. ${props.title}:`;
});

const subTitle = computed(() => {
  return props.subTitle;
});
</script>

import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";
import useHelpers from "composables/useHelpers.js";
import { useModuleStore } from "store/modules/moduleStore.js";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import store from "store";
import { useUserStore } from "store/modules/userStore.js";
import { useNotificationStore } from "store/modules/notificationStore.js";

export const useMenuStore = defineStore("menuItem", () => {
  const { userHasRole, checkRole } = useHelpers();
  const moduleStore = useModuleStore();
  const { hasSettingEnabled } = moduleStore;

  const userStore = useUserStore();
  const { fetchCurrentUser } = userStore;

  const notificationStore = useNotificationStore();
  const { fetchUnreadNotificationsCount } = notificationStore;

  const router = useRouter();
  const route = useRoute();

  // Refs
  const unreadNotificationsCount = ref(0);
  const menuIsOpen = ref(false);
  const notificationInterval =
    import.meta.env?.VITE_NOTIFICATION_FETCH_INTERVAL ?? 300000;

  // Computed
  const hideSubmenu = computed(() => {
    return (
      route?.meta?.hideSubmenu ||
      (subMenuItems.value.length === 1 && subMenuItems.value[0].path === "") ||
      false
    );
  });

  const canSeeHelpMenuItems = computed(() => {
    return !checkRole("student");
  });

  // MenuItems Computed
  const menuItems = computed(() => {
    const checkRoutes = (routes) => {
      const items = [];

      for (const route of routes) {
        if (
          route.meta?.isMenuItem &&
          userHasRole(route.meta?.roleRequired ?? []) &&
          (!route.meta.settingRequired ||
            route.meta.settingRequired.every(hasSettingEnabled))
        ) {
          let newRoute = { ...route };
          if (route.children) {
            newRoute.children = checkRoutes(route.children);
          }
          items.push(newRoute);
        }
      }

      return items;
    };

    return checkRoutes(router.options.routes).sort(
      (a, b) => a.meta.menuOrder - b.meta.menuOrder,
    );
  });

  const helpItems = computed(() => [
    {
      label: "Helpformulier",
      to: {
        name: "helpForm",
      },
    },
    {
      label: "Trainingen",
      href: "https://catwise.nl/training",
    },
    {
      label: "Kennisbank",
      href: "https://help.catwise.nl/",
    },
    {
      label: "Updates",
      href: "https://help.catwise.nl/article/108-updates",
    },
    {
      label: "Feedback sturen naar Catwise",
      to: {
        name: "feedbackForm",
      },
    },
  ]);

  // Items in the subMenu in Header.vue
  const subMenuItems = computed(() => {
    const baseRoutes = route.matched[0].children;
    const submenuRoutes = [];

    baseRoutes.map((route) => {
      subMenuChecklist(submenuRoutes, route);
    });

    return submenuRoutes;
  });

  // Items in the tabMenu in Header.vue
  const tabMenuItems = computed(() => {
    const baseRoutes = route?.matched[1]?.children;
    const tabMenuRoutes = [];

    if (!baseRoutes) return [];

    baseRoutes.map((route) => {
      tabMenuChecklist(tabMenuRoutes, route);
    });

    return tabMenuRoutes;
  });

  //  Methods
  const subMenuChecklist = (submenuRoutes, route) => {
    if (Array.isArray(route.children) && route.children.length > 0) {
      route.children.forEach((route) => subMenuChecklist(submenuRoutes, route));
    }

    if (
      !route?.meta?.isSubmenuItem ||
      (route?.meta?.settingRequired &&
        !route.meta.settingRequired.every(hasSettingEnabled))
    ) {
      return;
    }

    if (!userHasRole(route.meta?.roleRequired ?? [])) {
      return;
    }

    submenuRoutes.push(route);
  };

  const tabMenuChecklist = (tabMenuRoutes, route) => {
    if (Array.isArray(route.children) && route.children.length > 0) {
      route.children.forEach((route) => tabMenuChecklist(tabMenuRoutes, route));
    }

    if (!userHasRole(route.meta?.roleRequired ?? [])) {
      return;
    }

    tabMenuRoutes.push(route);
  };

  const fetchUnreadNotificationsWithInterval = () => {
    fetchUnreadNotificationsCount();
    setInterval(fetchUnreadNotificationsCount, notificationInterval);
  };

  const fetchUnfinishedTasksWithInterval = () => {
    setInterval(
      () => fetchCurrentUser({ forceFetch: true }),
      notificationInterval,
    );
  };

  const hasDropdownChild = (item) => {
    if (!item.children?.length) return false;

    item.children.every((child) => {
      if (!child.meta?.settingRequired?.every(hasSettingEnabled)) {
        return false;
      }
    });

    return item.children.some(
      (child) => child?.meta?.inDropdown && showSubItem(child),
    );
  };

  const showSubItem = (subItem) => {
    if (!subItem?.meta?.inDropdown) return false;

    if (subItem?.meta?.settingRequired) {
      return subItem?.meta?.settingRequired.every(hasSettingEnabled);
    }

    return true;
  };

  const toggleMenu = () => {
    menuIsOpen.value = !menuIsOpen.value;
  };

  const closeMenu = () => {
    menuIsOpen.value = false;
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const loginAsSchool = async (school) => {
    localStorage.setItem("school_id", school.id);
    localStorage.setItem("school_name", school.name);
    localStorage.setItem("user_name", school.username);

    let formData = new FormData();
    formData.set("school_id", school.id);

    return await axios.post("/api/login-as-school", formData).then((res) => {
      localStorage.removeItem("school_choices");
      store.dispatch("user/processResults", res.data);
    });
  };

  return {
    menuItems,
    helpItems,
    canSeeHelpMenuItems,
    subMenuItems,
    tabMenuItems,
    hideSubmenu,
    fetchUnreadNotificationsWithInterval,
    fetchUnfinishedTasksWithInterval,
    unreadNotificationsCount,
    toggleMenu,
    closeMenu,
    menuIsOpen,
    logout,
    loginAsSchool,
    hasDropdownChild,
    showSubItem,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot));
}
